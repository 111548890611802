







































import { Vue, Component } from 'vue-property-decorator';
import Header from './components/Header.vue';
import FreeTrialDlg from './components/FreeTrialDlg.vue';
import { loadingApply } from '@/api/home';
import common from '@/utils/common';
import utils from '@/utils/index';
@Component({
    name: 'EGGAlaiQandA',
    components: {
        Header,
        FreeTrialDlg
    }
})

export default class EGGAlaiQandA extends Vue {

        private showFreeTrailDlg = false;

        private handleFreeTrial() {
            this.showFreeTrailDlg = true;
            loadingApply({ country: '1001' });
            window.gtag('event', 'free_trial');
            utils.fbq('trackCustom', 'free_trial');
        }
}
